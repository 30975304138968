<template>
<b-container>
  <div class="row pr-4 pl-4 pb-5">
    <div class="banner"> 
      <b-img :src="company.banners" class="img-fluid"></b-img>
      <b-img :src="company.logo" class="card-img-logo"></b-img>

      <!-- <img
        class="card-img-logo"
        :src="company.logo"
        :alt="company && company.name && company.name.th"
      /> -->
    </div>
    <div class="content mb-5 pb-5">
      <h3>{{ job && job.title && (job.title.th || job.title.en) }}</h3>
      <p class="company">{{ company && company.name && company.name.th }}</p>
      <div class="salary">
        <span class="title">อัตราค่าจ้าง</span>  
        <span> {{ 
            job.salary && job.salary.value && job.salary.type === 'range' 
            ?  job.salary.value.join( ' - ') 
            : job.salary && job.salary.value 
            ? job.salary.value 
            : "N/A" 
            }}  {{ job.salary && job.salary.value && job.salary.currency 
          }}
        </span>
      </div>
      <div class="description">
        <span class="title">รายละเอียดงาน</span>
        <div v-html="job && job.descriptions && (job.descriptions.th || job.descriptions.en)" class="job-desc text-block"></div>
        <div v-if="job.qualifications">
          <br>
          <span class="title">คุณสมบัติ / ลักษณะงาน</span>
          <div v-html="job && job.qualifications && (job.qualifications.th || job.qualifications.en)" class="text-block"></div>
        </div>
      </div>
    </div>
    <divider />
    <div class="card w-100">
      <div class="row no-gutters">
        <div class="col-md-5 img-logo">
          <img
            v-if="job.company && job.company.banners"
            :src="job.company && job.company.banners"
            :alt="job.title.th"
          />
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <p class="card-title font-weight-bolder">
              {{ job.company && job.company.name && (job.company.name.th || job.company.name.en) }}
            </p>
            <p class="font-weight-bolder">Contact</p>
            <p>
              <!-- 219/13-15 5th Floor, Asoke Towers, Soi Asoke, Sukhumvit 21 Road, North Khlongtoey, Wattana, Bangkok 10110 -->
              {{ address && address.address && address.address.th }} {{ address && address.subdistrict && address.subdistrict.th }} {{ address && address.district && address.district.th }} {{ address && address.province && address.province.th }} {{ address && address.country && address.country.th }}
            </p>
            <div class="contact">
              <p>{{ company.tel }}</p>
              <p>{{ contact.email || contact.email && contact.email.th }}</p>
              <a :href="company.website">{{ company.website }}</a>
            </div>
            <div class="row">
              <router-link :to="`/companies/${company._id}/jobs`" class="col text-left">
                <button class="btn btn-primary btn-sm company">ดูโปรไฟล์บริษัท</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</b-container>
</template>

<script>
import apiService from "@/services/api.service";
import Divider from "@/components/Divider";
export default {
  components: {
    Divider
  },
  data() {
    return {
      job: {},
      company: {},
      contact: {},
      address: {},
    };
  },
  computed: {
    jobId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const { data } = await apiService.get(`/jobs/${this.jobId}`);
      this.job = data;
      this.company = data && data.company;
      this.contact = data && data.contact
      this.address =  data && data.contact && data.contact.address;
    },
  },
};
</script>
<style lang="scss" scoped>

.content { 
  margin: 1rem;
  width: 100%;
  h3 {
    font: normal normal bold 40px/21px adman x;
    color: #004D8D;
    margin: 1rem 0;
    line-height: 30px;
  }
  p.company {
    color: #5C5C5C;
  }
  .salary {
    color: #004D8D;
    .title {
      font-weight: 600;
      border-bottom: 1px solid #004D8D;
    }
  }
  .description {
    margin-top: 2.5rem;
    .title {
      color: #004D8D;
      font-weight: 600;
      border-bottom: 1px solid #004D8D;
    }
    .job-desc {
      margin-top: 1rem;
    }
  }
  .img-logo {
    width: 100%;
    img {
      max-width: 370px;
      /* max-height: 300px; */
      /* height: 200px; */
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
}
.card {
  border: none;
  .card-body {
    p {
      margin: 0;
    }
    padding: 0 1.25rem;
    .contact, a {
      color: #53A2DA;
      margin-bottom: 10px;
    }
    button.company {
      height: 40px;
      width: 120px;
    }
  }
  
}
.banner {
  position: relative;
  .img-fluid {
    width:100%;
    object-fit: cover;
    max-height: 300px;
  }
  .card-img-logo {
    position: absolute;
    right: 5vw;
    bottom: -5vw;
    width: 15%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 17px;
  }
}

</style>